import { BaseModel } from 'modules/core/models/base-model';

const APPROVED_WORKING_FORMULA          = 1;
const MANUFACTURING_PLANT_TRANSPOSITION = 2;
const NOT_APPLICABLE                    = 3;

export class BMTestReferenceType extends BaseModel {
    id   = null;
    name = null;

    static get APPROVED_WORKING_FORMULA() {
        return APPROVED_WORKING_FORMULA;
    }

    static get MANUFACTURING_PLANT_TRANSPOSITION() {
        return MANUFACTURING_PLANT_TRANSPOSITION;
    }

    static get NOT_APPLICABLE() {
        return NOT_APPLICABLE;
    }
}

